import React, { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const HeaderComponent = () => {
  const menuIconRef = useRef(null);
  const navbarRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const menuIcon = menuIconRef.current;
    const navbar = navbarRef.current;

    // Toggle Navbar
    const toggleNavbar = () => {
      menuIcon.classList.toggle('bx-x');
      navbar.classList.toggle('active');
    };

    // Close Navbar on Link Click
    const navLinks = navbar.querySelectorAll('a');
    navLinks.forEach((link) => {
      link.addEventListener('click', () => {
        menuIcon.classList.remove('bx-x');
        navbar.classList.remove('active');
      });
    });

    // Handle Scroll
    const handleScroll = () => {
      const top = window.scrollY;
      const sections = document.querySelectorAll('section');
      const navLinks = document.querySelectorAll('header nav a');

      // Section Highlighting
      sections.forEach((section) => {
        const offset = section.offsetTop - 100;
        const height = section.offsetHeight;
        const id = section.getAttribute('id');

        if (top >= offset && top < offset + height) {
          navLinks.forEach((link) => link.classList.remove('active'));
          const activeLink = document.querySelector(`header nav a[href*="${id}"]`);
          if (activeLink) activeLink.classList.add('active');
        }
      });

      // Sticky Navbar
      const header = document.querySelector('header');
      header.classList.toggle('sticky', top > 100);

      // Close Navbar
      menuIcon.classList.remove('bx-x');
      navbar.classList.remove('active');
    };

    menuIcon.addEventListener('click', toggleNavbar);
    window.addEventListener('scroll', handleScroll);

    // Cleanup on Component Unmount
    return () => {
      menuIcon.removeEventListener('click', toggleNavbar);
      window.removeEventListener('scroll', handleScroll);
      navLinks.forEach((link) => {
        link.removeEventListener('click', toggleNavbar);
      });
    };
  }, []);

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <header className="Header">
      <div className="logo"><b>AUA.</b></div>
      <div id="menu-icon" className="bx bx-menu" ref={menuIconRef}></div>
      <nav className="navbar" ref={navbarRef}>
        <Link to="/" onClick={() => handleNavigation('/')}>Home</Link>
        <Link to="/about" onClick={() => handleNavigation('/about')}>About</Link>
        <Link to="/services" onClick={() => handleNavigation('/services')}>Services</Link>
        <Link to="/contactus" onClick={() => handleNavigation('/contactus')}>Contact</Link>
      </nav>
    </header>
  );
};

export default HeaderComponent;
