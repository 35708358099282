import React, { useEffect, useRef } from 'react';


const Cursor = () => {
  const cursorDotRef = useRef(null);
  const cursorOutlineRef = useRef(null);

  useEffect(() => {
    const cursorDot = cursorDotRef.current;
    const cursorOutline = cursorOutlineRef.current;

    const moveCursor = (e) => {
      const posX = e.clientX;
      const posY = e.clientY;

      // Move cursor dot
      cursorDot.style.left = `${posX}px`;
      cursorDot.style.top = `${posY}px`;

      // Move cursor outline with animation
      cursorOutline.animate(
        {
          left: `${posX}px`,
          top: `${posY}px`
        },
        { duration: 500, fill: 'forwards' }
      );
    };

    // Attach the mousemove event listener
    window.addEventListener('mousemove', moveCursor);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('mousemove', moveCursor);
    };
  }, []);

  return (
    <div>
      <div className="cursor-dot" ref={cursorDotRef} data-cursor-dot></div>
      <div className="cursor-outline" ref={cursorOutlineRef} data-cursor-outline></div>
    </div>
  );
};

export default Cursor;
