import React from 'react'
import { motion } from 'framer-motion'
const Skill = () => {
  return (
    <div>
       <section className="skills" id="skills">
    <h2 className="heading">
    Worldwide <span> Best Digital Marketing Agency</span>
    </h2>

    <div className="skill-row">
      <motion.div 
           initial={{opacity: 0, x: -100}}
           whileInView={{opacity: 1, x: 0, transition: {delay: 0.5, duration: 0.5}}}
           viewport={{once: true}}
      
      className="skill-column">
               <div className='picsk'>

               </div>

              </motion.div>


      <motion.div 
         initial={{opacity: 0, x: -100}}
         whileInView={{opacity: 1, x: 0, transition: {delay: 1, duration: 0.5}}}
         viewport={{once: true}}
    
      
      className="skill-column-right">
            <p>
            AUA Innovation is a multifaceted company driven by a mission to deliver excellence. Our team comprises skilled professionals across various industries, allowing us to offer a diverse set of services to meet the evolving needs of our clients. We believe in creating lasting relationships by providing reliable, innovative solutions that bring real value.
            </p>
      
      </motion.div>
    </div>

  </section>
    </div>
  )
}

export default Skill
