import React from 'react'

const Iconanimation = () => {
  return (
    <div>
       <div className="icon-animate">


<div className="content-icon">

  <div className="marquee">
    <div className="marquee_blur" aria-hidden="true">
      <p className="marquee_text"> 
      Explore our wide range of professional services, tailored to help your business grow and achieve its goals. Whether you need precise CAD designs, robust web development, impactful digital marketing, or creative graphic design, AUA Industries has you covered
      </p>
    </div>
    <div className="marquee_clear">
      <p className="marquee_text"> 
      Explore our wide range of professional services, tailored to help your business grow and achieve its goals. Whether you need precise CAD designs, robust web development, impactful digital marketing, or creative graphic design, AUA Industries has you covered
      </p>
    </div>
  </div>

</div>
</div>

    </div>
  )
}

export default Iconanimation
