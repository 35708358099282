import React from 'react'
import './contact.css';

const Contactus = () => {
  return (
    <div className='contact-main-div'>
         
  <div className="container">
    <div className="content">
      <div className="left-side">
        <div className="address details">
        <i className='bx bx-map' ></i>
          <div className="topic">Address</div>
          <div className="text-one">Faisalabad,</div>
          <div className="text-two">Pakistan</div>
        </div>
        <div className="phone details">
        <i className='bx bx-phone-call'></i>
          <div className="topic">Phone</div>
          <div className="text-one">+923137102698</div>
<div className="text-two">+923107860717</div>
<div className="text-two">+923277210003</div>

        </div>
        <div className="email details">
        <i class='bx bx-envelope' ></i>
          <div className="topic">Email</div>
          <div className="text-one">auainnovations@gmail.com</div>
          <div className="text-two">info@auainnovations.com</div>
        </div>
      </div>
      <div className="right-side">
        <div className="topic-text">Send us a message</div>
        <p>Thank you for visiting AUA Innovations! If you have any questions, project inquiries, or need assistance with our services, we’d love to hear from you. Whether you're looking for cutting-edge web development, AutoCAD designs, social media strategies, or SEO expertise, we’re here to help. Get in touch today, and let’s turn your ideas into reality!</p>
        <div className="contact-link">
         
          <div className="social-links">
            <a href="https://www.facebook.com/profile.php?id=61569124254801&is_tour_completed" target="_blank" rel="noopener noreferrer">
              <i className="bx bxl-facebook"></i>
            </a>
            <a href="https://www.instagram.com/auainnovations/" target="_blank" rel="noopener noreferrer">
              <i className="bx bxl-instagram"></i>
            </a>
            <a href="https://www.linkedin.com/in/auainnovations/" target="_blank" rel="noopener noreferrer"><i className='bx bxl-linkedin'></i></a>
            <a href="https://wa.me/+923137102698" target="_blank" rel="noopener noreferrer">
              <i className="bx bxl-whatsapp"></i>
            </a>
          </div>
        </div>
    </div>
    </div>
  </div>

    </div>
  )
}

export default Contactus







