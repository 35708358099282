import React from 'react'

import Herosection from '../Components/Herosection'
import About from '../Components/About'
import Services from '../Components/Services'
import Iconanimation from '../Components/Iconanimation'
import Skill from '../Components/Skill'
import Experience from '../Components/Experience'


import Founders from "../Components/Founders"



const Home = () => {
  return (
    <div>
    
      <Herosection/>
      <About/>
      <Services/>
      <Iconanimation/>
      <Skill/>
      <Experience/>
      <Founders/>
   
     
     
      


    </div>
  )
}

export default Home
