
import React from 'react';


import './ServicesC.css';
import img77 from "../img/1-5.png"
import img78 from "../img/2-5.png"
import img79 from "../img/3-4.png"
import img80 from "../img/5-6.jpg"
import img81 from "../img/7-8.png"
import img82 from "../img/6-2.png"
import img83 from "../img/7-2.png"
import img84 from "../img/8-2.png"
import img85 from "../img/1212.jpg"
import img70 from "../img/49755.jpg"

const ServicespageC = () => {
  
  return (
    <div>
      <div className='serv68'>
        <div className='main-top-serv'>
                 <div className='heading'>
                  <h1>
                  SERVICES
                  </h1>
                 </div>
        </div>
        <div className='serv-content'>
        <h2 className="heading">
      Our<span> Services</span>
    </h2>
    <div className='services-show'>
    <div className='head'><h1> Graphic Design</h1></div>
        <div className='services-img'>
              <img src={img77} alt='graphic design'/>
        </div>
        <div className='services-content99'>
     
           <p>
           -Logo Design
           <br/>
           -Branding
<br/>
           -Illustration
<br/>
-Infographics
<br/>
-Print Design
<br/>
-Photo Editing
<br/>
-Menu Design & Price Lists
<br/>
-Flyer Designs
<br/>
-Poster Designs
<br/>
-Business Cards & Email Signatures
<br/>
-Social Media Posts
<br/>
-YouTube Thumbnails
<br/>
-Presentations
           </p>
           </div>
           </div>


           <div className='services-show'>
           <div className='head'> <h1>Website Development</h1></div>
        <div className='services-img'>
              <img src={img78} alt='graphic design'/>
        </div>
        <div className='services-content99'>
     
           <p>
           -Business Website
           <br/>
-Portfolio Website
<br/>
-Blog Website
<br/>
-E-Commerce
<br/>
-Website Customization
<br/>
-Shopify Store 
<br/>
-Wordpress Development
<br/>
-MERN Stack Development
<br/>

           </p>
           </div>
           </div>
           <div className='services-show'>
           <div className='head'> <h1>Content Writting</h1></div>
        <div className='services-img'>
              <img src={img79} alt='graphic design'/>
        </div>
        <div className='services-content99'>
          
           <p>
           -Keyword Research
           <br/>
-SEO Optimize rticle
<br/>
-Proper ON-Page SEO
<br/>
-Article Publishing
<br/>
-Pictures According to Content 

           </p>
           </div>
           </div>
           <div className='services-show'>
           <div className='head'> <h1>AutoCAD</h1></div>
        <div className='services-img'>
              <img src={img70} alt='graphic design'/>
        </div>
        <div className='services-content99'>
          
           <p>
           -2D Design
           <br/>
-3D Design
<br/>
-Elevation
<br/>
-Working Design<br/>
-Moldings Design
<br/>


           </p>
           </div>
           </div>
           <div className='services-show'>
           <div className='head'>  <h1>SEO (Search Engine optimization)</h1></div>
        <div className='services-img'>
              <img src={img80} alt='graphic design'/>
        </div>
        <div className='services-content99'>
           
           <p>
           
-Guest Posting
<br/>
-Backlinks
<br/>
-Keyword Research
<br/>
-Website Audit
<br/>
-Competitor Analysis
<br/>
-ON-Page SEO
<br/>
-Off-Page SEO
<br/>
-Technical SEO
<br/>

           </p>
           </div>
           </div>
           <div className='services-show'>
           <div className='head'>  <h1>Social Media Handelling </h1></div>
        <div className='services-img'>
              <img src={img81} alt='graphic design'/>
        </div>
        <div className='services-content99'>
           
           <p>
           -Facebook Handelling
           <br/>
-linkedin handling
<br/>
-pinterest Handling
<br/>
-Instagram Handling

           </p>
           </div>
           </div>
           <div className='services-show'>
           <div className='head'>  <h1>Lead Generation  </h1></div>
        <div className='services-img'>
              <img src={img82} alt='graphic design'/>
        </div>
        <div className='services-content99'>
           
           <p>
           -B2B Lead Generation
           <br/>
           -B2C Lead Generation 

           </p>
           </div>
           </div>
           <div className='services-show'>
           <div className='head'>  <h1>Data Scraping  </h1></div>
        <div className='services-img'>
              <img src={img83} alt='graphic design'/>
        </div>
        <div className='services-content99'>
           
           <p>
           -Web Scraping
           <br/>
-Google Map Data Scraping
<br/>
-Data Scraping from Business Directories 
           </p>
           </div>
           </div>
           <div className='services-show'>
           <div className='head'>  <h1>Cold Emailing </h1></div>
        <div className='services-img'>
              <img src={img84} alt='graphic design'/>
        </div>
        <div className='services-content99'>
           
           <p>
           -Set up COld E-mail
           <br/>
-DNS Management
<br/>
-Configure MX, SPF, DKIM, and DMARC
<br/>
-Connect Email accounts with AI
<br/>
-Enable Email Forwarding
<br/>
-Email Warmup
<br/>
-Campaign Management
<br/>
-Cold Email Infrastructure
<br/>
           </p>
           </div>
           </div>
           <div className='services-show'>
           <div className='head'>  <h1>Business Management</h1></div>
        <div className='services-img'>
              <img src={img85} alt='graphic design'/>
        </div>
        <div className='services-content99'>
           
           <p>
           -Administration
           <br/>
-Operational Management
<br/>
-Prouncement Handeling
<br/>
-Accounts Management
<br/>
-Invoice Generation
<br/>
-Book Keeping
<br/>
           </p>
           </div>
           </div>















        </div>
      </div>
    </div>
  )
}

export default ServicespageC
