import React from 'react'
import ServicespageC from '../Components/ServicespageC'

const Servicespage = () => {
  return (
    <div>
      <ServicespageC/>
    </div>
  )
}

export default Servicespage
