import React from 'react'
import img55 from "../img/about.jpg"
import img56 from "../img/fd.png"
import img57 from '../img/ffd.png'
import { motion } from 'framer-motion'

const Founders = () => {
  return (
    <div>
      <section className='founders'>
 
<motion.div
    initial={{opacity: 0, scale: 2}}
    whileInView={{opacity: 1, scale: 1, transition: {delay: 0.8, duration: 0.8}}}
    viewport={{once: true}}
className="flip-card">
  <div className="flip-card-inner">
    <div className="flip-card-front">
      <div className="profile-image">
        <div className='pfp'> <img src={img55} alt='ceo'/></div>
     
        <div className="name">
          ALI HAMZA
          <p>Founder</p>
        </div>
      </div>
    </div>
    <div className="flip-card-back">
      <div className="Description">
        <p className="description">
        I am a skilled MERN Stack Developer, specializing in building fast, scalable, and secure web applications with a focus on user-friendly designs and seamless functionality.
        </p>
        <div className="socialbar">
      
     
      <a href="https://wa.me/+923137102698"><i className='bx bxl-whatsapp'></i></a>
          </div>
      </div>
    </div>
  </div>
</motion.div>
 
<motion.div 
 initial={{opacity: 0, scale: 2}}
 whileInView={{opacity: 1, scale: 1, transition: {delay: 0.8, duration: 0.8}}}
 viewport={{once: true}}

className="flip-card">
  <div className="flip-card-inner">
    <div className="flip-card-front">
      <div className="profile-image">
        <div className='pfp'> <img src={img56} alt='ceo'/></div>
     
        <div className="name">
           USAMA
          <p>Founder</p>
        </div>
      </div>
    </div>
    <div className="flip-card-back">
      <div className="Description">
        <p className="description">
        am an AutoCAD expert specializing in precise 2D drafting and 3D modeling. I deliver accurate, detailed designs tailored to your project requirements.
        </p>
        <div className="socialbar">
     
      <a href="https://wa.me/+923277210003"><i className='bx bxl-whatsapp'></i></a>
          </div>
      </div>
    </div>
  </div>
</motion.div>
 
<motion.div

initial={{opacity: 0, scale: 2}}
whileInView={{opacity: 1, scale: 1, transition: {delay: 0.8, duration: 0.8}}}
viewport={{once: true}}

className="flip-card">
  <div className="flip-card-inner">
    <div className="flip-card-front">
      <div className="profile-image">
        <div className='pfp'> <img src={img57} alt='ceo'/></div>
     
        <div className="name">
          AHMAD
          <p>Founder</p>
        </div>
      </div>
    </div>
    <div className="flip-card-back">
      <div className="Description">
        <p className="description">
        I am a Social Media and SEO expert, dedicated to boosting online visibility, driving engagement, and optimizing content to achieve top search rankings and grow your brand.
        </p>
        <div className="socialbar">

      <a href="https://wa.me/+923107860717"><i className='bx bxl-whatsapp'></i></a>
          </div>
      </div>
    </div>
  </div>
</motion.div>

     


      </section>
    </div>
  )
}

export default Founders
