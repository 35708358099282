import React from 'react'
const Experience = () => {
  return (
    <div>
      <section className="experience" id="experience">
    <h2 className="heading">
     <span></span>
    </h2>

    <div className="experience-row">
     


      <div className="experience-column">
                <div className="experience-box">
              
<div className="e-card">
  <div 

  className="item item--1">
 
    <span className="quantity"> 5+ Year </span>
    <span className="e-text text--1"> Experience </span>
  </div>
  <div
 
  
  className="item item--2">

    <span className="quantity"> 1000+ </span>
    <span className="e-text text--2"> Projects</span>
  </div>
  <div

  className="item item--3">
  
    <span className="quantity"> 20+ </span>
    <span className="e-text text--3"> Skills </span>
  </div>
  <div

  className="item item--4">

    <span className="quantity"> 3000+ </span>
    <span className="e-text text--4"> Task Complete </span>
  </div>
  <div

  
  className="item item--5">
 
    <span className="quantity"> 12X </span>
    <span className="e-text text--4"> Faster order processing  </span>
  </div>
</div>

                </div>
      </div>
    </div>
  </section>

    </div>
  )
}

export default Experience
