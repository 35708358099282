import React from 'react'
import { motion } from 'framer-motion'

const Services = () => {
  return (
    <div>
      
  <section className="services" id="services">
    <h2 className="heading">
      Our<span> Services</span>
    </h2>
    <motion.div
        initial={{opacity: 0, y: 100}}
        whileInView={{opacity: 1, y: 0, transition: {delay: 0.5, duration: 0.5}}}
        viewport={{once: true}}
    className="section-services">
    <div className="cardBox">
      <div className="card29">
      <i class='bx bx-code-alt'></i>
         <div className="h4">Web Development</div>
       
        <div className="content29">
          <div class="h3">Web Development</div>
          <p>We build fast, responsive websites and applications tailored to your business, enhancing your online presence and engagement.</p>
        </div>
      </div>
    </div>
    <div className="cardBox">
      <div className="card29">
      <i class='bx bx-layer' ></i>
         <div className="h4">Graphic Design</div>
       
        <div className="content29">
          <div class="h3">Graphic Design</div>
          <p> Creative, brand-focused design for logos, marketing materials, and digital content to make your brand memorable.</p>
        </div>
      </div>
    </div>
    <div className="cardBox">
      <div className="card29">
      <i class='bx bxs-videos'></i>
         <div className="h4">Video Editing</div>
       
        <div className="content29">
          <div class="h3">Video Editing</div>
          <p> Are you looking for a skilled video editor to bring your ideas to life? Look no further! I offer high-quality video editing services tailored to meet your unique needs.</p>
        </div>
      </div>
    </div>
    <div className="cardBox">
      <div className="card29">
      <i class='bx bx-building-house' ></i>
         <div className="h4">AutoCAD Services</div>
       
        <div className="content29">
          <div class="h3">AutoCAD Services</div>
          <p> Precise 2D and 3D drafting and design for architecture, engineering, and product needs, bringing your concepts to life with accuracy.</p>
        </div>
      </div>
    </div>
    <div className="cardBox">
      <div className="card29">
      <i class='bx bx-signal-5'></i>
         <div className="h4">SEO</div>
        <div className="content29">
          <div class="h3">SEO</div>
          <p> Boost your website's visibility with targeted SEO strategies to attract the right audience and improve search engine rankings.</p>
        </div>
      </div>
    </div>
    <div className="cardBox">
      <div className="card29">
      <i class='bx bx-line-chart'></i>
         <div className="h4">Digital Marketing</div>
       
        <div className="content29">
          <div class="h3">Digital Marketing</div>
          <p> Personalized digital marketing solutions, from social media to email, that drive conversions and strengthen customer loyalty.</p>
        </div>
      </div>
    </div>
    <div className="cardBox">
      <div className="card29">
      <i class='bx bx-edit-alt' ></i>
         <div className="h4"> Content Writing</div>
       
        <div className="content29">
          <div class="h3">Content Writing</div>
          <p> High-quality, SEO-optimized content writing services that engage readers, communicate your brand’s message, and improve search visibility.</p>
        </div>
      </div>
    </div>
    <div className="cardBox">
      <div className="card29">
      <i class='bx bx-cart'></i>
         <div className="h4">E-commerce Development</div>
       
        <div className="content29">
          <div class="h3">E-commerce Development</div>
          <p> Customized, user-friendly e-commerce solutions that simplify online shopping experiences, boost sales, and enhance customer satisfaction.</p>
        </div>
      </div>
    </div>
    <div className="cardBox">
      <div className="card29">
      <i class='bx bx-world' ></i>
         <div className="h4">Social Media Management</div>
       
        <div className="content29">
          <div class="h3">Social Media Management</div>
          <p> Comprehensive social media management to build brand awareness, foster engagement, and drive growth across all major platforms.</p>
        </div>
      </div>
    </div>
    <div className="cardBox">
      <div className="card29">
      <i class='bx bx-envelope' ></i>
         <div className="h4">Email Marketing</div>
       
        <div className="content29">
          <div class="h3">Email Marketing</div>
          <p>Targeted email marketing campaigns that reach your audience directly, build loyalty, and boost conversions with personalized messaging.</p>
        </div>
      </div>
    </div>


      
   

  

    </motion.div>
  </section>
    </div>
  )
}

export default Services
