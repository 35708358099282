import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Curser from './Components/Curser';
import HeaderComponent from './Components/Header';
import Footer from './Components/Footer';
import Contactus from './pages/Contactus';
import Servicespage from './pages/Servicespage';
import Aboutpage from './pages/Aboutpage';
import NotFound from './pages/NotFound';  // Make sure this page exists
import ScrollToTop from './Components/ScrollToTop';

function App() {
  return (
    <Router>
      <>
      <ScrollToTop/>
        <Curser />
        <HeaderComponent />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/services" element={<Servicespage />} />
          <Route path="/about" element={<Aboutpage />} />
          <Route path="*" element={<NotFound />} /> {/* Catch-all for 404 */}
        </Routes>
        <Footer />
      </>
    </Router>
  );
}

export default App;