import React, { useEffect } from 'react';
import img1 from '../img/business_right_image.png';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const About = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate('/services'); // Navigate to the Services page
    window.scrollTo(0, 0); // Scroll to the top after navigation
  };

  useEffect(() => {
    // Ensure the page starts at the top when the component loads
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <section className="about" id="about">
        <div className="side-text"></div>
        <h2 className="heading">
          About <span>AUA Innovation's</span>
        </h2>
        <motion.div
          initial={{ opacity: 0, scale: 2 }}
          whileInView={{ opacity: 1, scale: 1, transition: { delay: 0.8, duration: 0.8 } }}
          viewport={{ once: true }}
          className="about-img"
        >
          <img src={img1} alt="mypic" />
          <span className="circle-spin"></span>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0, transition: { delay: 1, duration: 0.5 } }}
          viewport={{ once: true }}
          className="about-content"
        >
          <h3>Transforming Visions into Reality</h3>
          <p>
            AUA Innovation is a forward-thinking enterprise focused on delivering exceptional services across a variety
            of industries. Our expertise spans a wide range of fields, and we are committed to offering innovative,
            high-quality solutions that help our clients achieve their goals. Built on the pillars of reliability,
            integrity, and customer satisfaction, AUA Industries strives to create long-term partnerships with each
            client, making their success our priority.
            <br />
            Our mission is simple: to drive progress through excellence, adapting to industry changes and leveraging
            our expertise to provide meaningful impact. At AUA Industries, we are not just about delivering results;
            we’re about delivering excellence in every project. Join us as we work together towards a brighter, more
            prosperous future.
          </p>
          <div className="btn-box btns">
            <button className="cti" onClick={handleNavigation}>
              <div className="CTI">All services</div>
            </button>
          </div>
        </motion.div>
      </section>
    </div>
  );
};

export default About;
